<template>
  <el-container>
    <div class="user-info">
      <div class="user-info-left">
        <img :src="userInfo.avatar ? userInfo.avatar : require('../../assets/user.png')" />
        <div class="name">
          <div>{{ userInfo.u_account }}</div>
          <div>
            <span>{{ userInfo.name ? userInfo.name : userInfo.u_account }}</span>
            <router-link to="/shop/set">设置</router-link>
          </div>
        </div>
      </div>
      <div v-if="userInfo.role_id == '1'">
        <el-button v-if="userInfo.is_system" style="margin-right: 10px" type="primary" size="small" icon="el-icon-plus" @click="$refs.manage.drawer = !0">系统管理</el-button>
        <router-link to="/shop/createShop" style="margin-right: 10px">
          <el-button type="primary" size="small" icon="el-icon-plus">新建站点</el-button>
        </router-link>
        <router-link to="/shop/employee">
          <el-button type="primary" size="small" icon="el-icon-user">员工管理</el-button>
        </router-link>
      </div>
    </div>
    <div class="site-total" v-if="userInfo.role_id == '1'">
      <img src="../../assets/image/z_num.png" alt="" />
      <div class="num">
        总计
        <span>{{ user_info.total_account_num }}</span>
        站点，还可以开通
        <span>{{ user_info.account_num }}</span>
        站点
      </div>
    </div>
    <div class="site-list">
      <div class="title">
        <i v-show="scrollWidth > 0" class="el-icon-arrow-left" @click="scrollLeft"></i>
        <div ref="case_list" class="case-list">
          <div class="case-box" :style="move">
            <div :class="[item.id == case_id ? 'active' : '']" @click="selectCase(item.id)" v-for="(item, index) in caseList" :key="index">
              {{ item.name }}
            </div>
          </div>
        </div>
        <i v-show="scrollWidth <= 0" class="el-icon-arrow-right" @click="scrollRight"></i>
        <el-input placeholder="请输入内容" size="small" v-model="keyword">
          <el-button slot="append" size="small" style="color: #969699; font-size: 15px" @click="search" type="primary" icon="el-icon-search"></el-button>
        </el-input>
      </div>
      <ul class="site-list-data">
        <template v-if="siteList.length">
          <li class="item" @click="innerSite(item)" @mousemove="siteList[index].is_edit = !0" @mouseout="siteList[index].is_edit = !1" v-for="(item, index) in siteList" :key="index">
            <div class="status" :style="{ background: item.day > 0 ? '#2fb72f' : '' }">
              {{ item.endtime - item.now_time > 0 ? '使用中' : '已过期' }}
            </div>
            <div class="status version">
              <span>{{ item.meal.name }}</span>
            </div>
            <div class="site-info">
              <el-image :src="item.logo"></el-image>
              <div class="sis-right">
                <div class="name">{{ item.name }}</div>
                <div class="desc">appid:{{ item.app_id }}</div>
                <div class="desc">
                  剩余天数：{{ item.day }}天
                  <span @click.stop="renew(item.uniacid)" v-show="item.is_edit && Number(item.meal.price) > 0">续费</span>
                </div>
                <div class="edits" v-show="item.is_edit">
                  <span @click.stop="edit(item)">编辑</span>
                  <span @click.stop="remove(item.uniacid)">删除</span>
                </div>
              </div>
            </div>
            <div class="version-name">
              <div>{{ item.case_id.name }}</div>
            </div>
          </li>
        </template>
        <No v-else />
      </ul>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="pagesize"
          layout="total, prev, pager, next"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <Manage ref="manage"></Manage>
    <el-dialog title="编辑站点信息" :visible.sync="is_edit" width="30%">
      <div class="edit">
        <div class="name">
          <span>站点名称：</span>
          <el-input v-model="name"></el-input>
        </div>
        <div class="logo">
          <span>站点logo：</span>
          <el-input :disabled="true" v-model="logo">
            <el-button slot="append" @click="showImgLibrary = !0" icon="el-icon-upload"></el-button>
          </el-input>
        </div>
        <div class="img">
          <img :src="logo" alt="" />
        </div>
      </div>
      <ImgLibrary v-if="showImgLibrary" :url="1" @changeStatus="changeStatus" :dialogVisible="showImgLibrary" @getImg="getImg"></ImgLibrary>
      <span slot="footer" class="dialog-footer">
        <el-button @click="is_edit = !1">取 消</el-button>
        <el-button type="primary" @click="updateSite">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import ImgLibrary from '@/components/imgLibrary';
import Manage from '@/components/manage';
export default {
  components: {
    ImgLibrary,
    Manage,
  },
  data() {
    return {
      is_edit: !1,
      keyword: '',
      siteList: [],
      page: 1,
      pagesize: 6,
      total: 0,
      logo: '',
      name: '',
      uniacid: '',
      user_info: {},
      is_scroll: !1,
      scrollWidth: 0,
      move: {},
      showImgLibrary: !1,
      caseList: [
        {
          id: 0,
          name: '全部站点',
        },
      ],
      case_id: 0,
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  created() {
    this.getAccountList();
  },
  mounted() {
    this.getCase();
  },
  methods: {
    changeStatus(val) {
      this.showImgLibrary = val;
    },
    scrollLeft() {
      this.move = {
        transform: `translateX(0px)`,
        transition: 'all 0.3s linear',
      };
      this.scrollWidth = 0;
    },
    scrollRight() {
      if (!this.scrollWidth) this.scrollWidth = this.$refs.case_list.scrollWidth - this.$refs.case_list.clientWidth;
      if (this.scrollWidth > 0) {
        this.move = {
          transform: `translateX(-${this.scrollWidth}px)`,
          transition: 'all 0.3s linear',
        };
      }
    },
    //获取套餐
    getCase() {
      this.$axios.post(this.$api.site.getCase).then(res => {
        if (res.code == 0) {
          this.caseList.push(...res.result);
          this.$nextTick(() => {
            if (this.$refs.case_list.scrollWidth > this.$refs.case_list.clientWidth) this.is_scroll = !0;
          });
        }
      });
    },
    selectCase(id) {
      if (this.case_id != id) {
        this.page = 1;
        this.case_id = id;
        this.getAccountList();
      }
    },
    //站点列表
    getAccountList() {
      this.$axios
        .post(this.$api.site.accountList, {
          keyword: this.keyword,
          page: this.page,
          pagesize: this.pagesize,
          case_id: this.case_id,
        })
        .then(res => {
          if (res.code == 0) {
            let list = res.result.data;
            this.user_info = res.result.user_info;
            if (list) {
              list.map(i => {
                i.is_edit = !1;
                let now_time = parseInt(new Date().getTime() / 1000);
                let sec = i.endtime - now_time;
                let day = parseInt(sec / 24 / 3600);
                if (day < 0) day = 0;
                i.now_time = now_time;
                i.day = day;
              });
              this.siteList = list;
              this.total = res.result.total;
            } else {
              this.siteList = [];
              this.total = 0;
            }
          }
        });
    },
    getImg(val) {
      if (val.length) this.logo = val[0].path;
      this.showImgLibrary = !1;
    },
    //搜索站点
    search() {
      this.getAccountList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getAccountList();
    },
    //进入站点
    innerSite(e) {
      this.$store.dispatch('user/enterProgram', e);
    },
    renew(id) {
      this.$router.push({
        path: `/shop/shopRenew`,
        query: {
          id: id,
        },
      });
    },
    edit(item) {
      this.logo = item.logo;
      this.name = item.name;
      this.uniacid = item.uniacid;
      this.is_edit = !0;
    },
    //修改站点
    updateSite() {
      this.$axios
        .post(this.$api.site.updateAccountInfo, {
          name: this.name,
          logo: this.logo,
          uniacid: this.uniacid,
        })
        .then(res => {
          if (res.code == 0) {
            this.name = '';
            this.logo = '';
            this.getAccountList();
            this.is_edit = !1;
            this.$message.success('修改成功');
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    //删除站点
    remove(id) {
      this.$confirm('确认要将站点删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.site.delAccount, {
            uniacid: id,
          })
          .then(res => {
            if (res.code == 0) {
              this.$message({
                message: '删除成功',
                type: 'success',
              });
              this.getAccountList();
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  align-items: center;
  flex-direction: column;
  .user-info {
    height: 100%;
    width: 950px;
    margin-top: 20px;
    height: 71px;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: space-between;
    padding: 0 20px;

    .user-info-left {
      display: flex;
      align-items: center;
      font-size: 14px;

      img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .name a {
        color: #1890ff;
        margin-left: 5px;
      }
    }
  }

  .site-total {
    width: 950px;
    height: 84px;
    background: #e5f2ff;
    border: 1px solid #409eff;
    padding: 0 30px;
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-top: 15px;
    img {
      margin-right: 15px;
    }
    span {
      font-size: 20px;
      color: #409eff;
    }
  }

  .site-list {
    width: 950px;
    background: #fff;
    margin-top: 20px;
    padding: 12px 20px 20px 20px;
    .title {
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
      i {
        color: #999;
        font-weight: bold;
        cursor: pointer;
      }
      .case-list {
        display: flex;
        font-size: 14px;
        color: #999;
        overflow-x: scroll;
        white-space: nowrap;
        &::-webkit-scrollbar {
          height: 0;
          width: 0;
        }
        .case-box {
          display: flex;
          div {
            margin-right: 16px;
            cursor: pointer;
          }
        }

        .active {
          color: #333;
        }
      }
      .el-input {
        width: 240px;
      }
    }

    .site-list-data {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      border-top: 1px solid #f4f4f4;
      padding-top: 12px;

      .no {
        text-align: center;
        flex: 1;
        margin-top: 20px;
      }

      .item {
        height: 164px;
        border: 1px solid #f0f0f0;
        margin: 0 12px 12px 0;
        padding: 30px 16px;
        cursor: pointer;
        position: relative;
        width: 270px;
        box-sizing: border-box;
        position: relative;

        .status {
          position: absolute;
          height: 18px;
          font-size: 12px;
          background: #0c6;
          color: #fff;
          left: 0;
          top: 0;
          padding: 0 12px;
          border-radius: 0 0 18px 0;
          line-height: 18px;
          z-index: 11;
          background: #f06;
        }

        .version {
          padding: 0 16px 0 64px;
          z-index: 9;
          background: #96f;
        }
        .version-name {
          display: flex;
          justify-content: center;
          margin-top: 10px;
          div {
            padding: 0 14px;
            height: 24px;
            line-height: 24px;
            background: #e5f2ff;
            border-radius: 4px;
            color: #409eff;
            font-size: 12px;
          }
        }
        .site-info {
          display: flex;

          .el-image {
            width: 80px;
            height: 80px;
            margin-right: 12px;
            border-radius: 6px;
          }

          .sis-right {
            padding-top: 5px;

            .name {
              font-size: 16px;
              font-weight: bold;
            }

            .desc {
              font-size: 13px;
              color: #999;
              margin-top: 4px;

              span {
                color: #39f;
              }
            }

            .edits {
              color: #39f;
              font-size: 13px;
              margin-top: 4px;

              span {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
}

.edit {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 50px;

  .name,
  .logo {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .el-input {
      flex: 1;
    }
  }

  .img {
    align-items: flex-start;

    img {
      width: 80px;
      height: 80px;
      border: 1px solid #f4f4f4;
    }
  }
}
</style>
